<template>
  <tr
    class="tc-table-row"
    :class="{
      'cursor-pointer': rowHasSubRows(row),
      'toggled': isRowToggled,
    }"
    :data-row-slug="row.slug"
    @click="rowHasSubRows(row) ? toggleRow(row.slug) : null"
  >
    <td
      v-for="(value, colIndex) in row.data"
      :key="colIndex"
      v-tooltip="{
        content: typeof headers[colIndex]?.tooltipFn === 'function' && value !== NO_VALUE
          ? headers[colIndex].tooltipFn(row.data[colIndex])
          : null,
        placement: 'top-start',
        container: 'body',
        distance: 8,
        arrowPadding: 32,
      }"
      v-tc-stick-to.invert="colIndex === 0 && row.slug === rows?.[0].slug ? '.tc-table' : null"
    >
      <button
        v-if="rowHasSubRows(row) && colIndex === 0"
        class="tc-table-cell-chevron btn btn-icon btn-icon-sm btn-thin btn-geyser"
      >
        <i
          class="zmdi zmdi-hc-lg zmdi-chevron-right"
          :style="`${ isRowToggled ? 'transform: rotate(90deg) translateY(1px)' : '' }`"
        />
      </button>
      <span
        v-if="headers[colIndex].label.length"
        class="tc-table-cell-label"
      >{{ headers[colIndex].label }}</span>
      <div class="tc-table-cell-value">
        <slot
          :name="'column-' + colIndex"
          :value="value"
          :row="row"
        >
          {{ value ?? '-' }}
        </slot>
      </div>
    </td>
  </tr>
</template>

<script setup>
import { nextTick } from 'vue';
import { useBreakpoints } from '@vueuse/core';

const props = defineProps({
  row: Object,
  rows: Array,
  headers: Array,
  toggleRow: Function,
  isRowToggled: {
    type: Boolean,
    default: false,
  },
});

const NO_VALUE = '–';

const breakpoints = useBreakpoints({ large: 992 });

const rowHasSubRows = (row) => row.children.paginator?.stack?.length;

const scrollToFirstRow = (rowSlug) => {
  if (breakpoints.greaterOrEqual('large').value) return;
  const firstRowCells = document.querySelectorAll(`.tc-table-row[data-row-slug="${rowSlug}"] td`);
  const firstSubRow = document.querySelector(`.tc-table-row[data-row-slug="${rowSlug}"] + .tc-table-sub-row`);
  const scrollParent = document.querySelector('.col-main');
  if (!firstSubRow || !firstRowCells?.[0] || !scrollParent) return;
  setTimeout(() => {
    const scrollParentOffsetTop = (scrollParent.children?.[0] && scrollParent.children[0].offsetTop + 16) || 112;

    if (firstSubRow.offsetTop === 0) { // Close accordion
      const firstRowHeight = [...firstRowCells].reduce((acc, td) => acc + td.offsetHeight || 0, 0);
      const firstRowCellLastChildMarginBottom = 48; // 3*16; // firstRowCells[firstRowCells.length - 1].style.marginBottom;
      scrollParent.scrollTo({
        top: firstRowCells[0].offsetTop - firstRowHeight + firstRowCellLastChildMarginBottom - scrollParentOffsetTop,
        behavior: 'smooth',
      });
    } else { // Open accordion
      scrollParent.scrollTo({
        top: firstSubRow.offsetTop - scrollParentOffsetTop,
        behavior: 'smooth',
      });
    }
  }, 1);
};

const toggleRow = (rowSlug) => {
  props.toggleRow(rowSlug);
  nextTick(() => scrollToFirstRow(rowSlug));
};
</script>
