<template>
  <VMenu
    :shown="isOpen"
    :triggers="[]"
    :popper-triggers="[]"
    placement="bottom-start"
    :distance="4"
    :auto-hide="false"
    v-bind="popperSettings ?? {}"
  >
    <div
      v-bind="$attrs"
      :class="{
        'tc-datepicker': !useBadgeStyling,
        'badge badge-grid badge-filter strong': useBadgeStyling,
        'focus-briefly': useFocusBriefly && modalCardOpenedFrom === 'date',
        'disabled': disabled,
      }"
    >
      <button
        type="button"
        :aria-expanded="isOpen"
        :aria-haspopup="true"
        :aria-controls="isOpen ? 'tc-datepicker' : null"
        :aria-disabled="disabled || false"
        :aria-label="$pgettext('Tooltip — Open date picker', 'Öppna datumväljare')"
        :class="{
          'datepicker-btn btn-text': !useBadgeStyling,
          'btn-link btn-text': useBadgeStyling,
          active: isOpen
        }"
        @click.stop.prevent="toggleDatePicker"
      >
        <span
          class="compare-date-filter pr-0"
          :class="{
            'tc-select-inline-value': !useBadgeStyling,
            'strong': useBadgeStyling
          }"
        >
          {{ readableDate }}
        </span>
      </button>
      <div
        v-if="removable"
        v-tooltip="{
          content: () => $pgettext('Tooltip — Remove badge', 'Ta bort'),
          distance: 8,
          container: 'body',
          placement: 'right',
        }"
        class="remove"
        :aria-label="$pgettext('Tooltip — Remove badge', 'Ta bort')"
        @click.prevent="$emit('remove')"
      >
        <i class="zmdi zmdi-close" />
      </div>
    </div>

    <template #popper>
      <div
        class="tc-datepicker-input datepicker px-0.5 py-0.5"
        :class="{ active: isOpen }"
        @click.stop.prevent=""
      >
        <div class="datepicker-calendar">
          <div class="datepicker-calendar-header mb-0.5">
            <div class="datepicker-calendar-year">
              <span
                class="datepicker-prev-year"
                @click.stop.prevent="changeYear(-1)"
              >
                <button
                  class="btn btn-icon btn-ghost"
                  type="button"
                  :aria-disabled="year <= 2015 || null"
                >
                  <i class="zmdi zmdi-chevron-left" />
                </button>
              </span>

              <transition-group
                name="slide-calendar"
                mode="out-in"
                type="animation"
                class="datepicker-calendar-year-text"
                :class="yearTransitionClasses"
                tag="span"
              >
                <span :key="year">
                  {{ year }}
                </span>
              </transition-group>
              <span
                class="datepicker-next-year"
                @click.stop.prevent="changeYear(1)"
              >
                <button
                  class="btn btn-icon btn-ghost"
                  type="button"
                  :aria-disabled="yearLimit === year || null"
                >
                  <i class="zmdi zmdi-chevron-right" />
                </button>
              </span>
            </div>
          </div>
          <div class="datepicker-calendar-body">
            <div class="datepicker-calendar-quarters">
              <ul class="plain-list">
                <li
                  v-for="quarter in quarters"
                  :key="quarter.id"
                  :aria-disabled="disabledQuarters.includes(quarter) || null"
                  :class="{ 'active range start-date': showQuarterClasses(quarter+'-'+year) }"
                  @click.stop.prevent="selectQuarter(quarter);"
                >
                  <button
                    class="btn btn-text btn-text-clean"
                    type="button"
                  >
                    {{ quarter }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="datepicker-calendar-months">
              <ul class="plain-list">
                <li
                  v-for="month in months"
                  :key="month.id"
                  class="cursor-pointer"
                  :class="monthClasses(month)"
                  :aria-disabled="month.disabled || null"
                  @click.stop.prevent="selectMonth(month)"
                >
                  <button
                    class="btn btn-text btn-text-clean"
                    type="button"
                  >
                    {{ month.label }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="divider" />
        <aside class="datepicker-predefined">
          <ul class="plain-list">
            <li
              v-for="predefinedDate in predefinedDates"
              :key="predefinedDate.id"
              :class="{ 'active range start-date end-date': selected === predefinedDate.value }"
              @click.stop.prevent="selectPredefinedDates(predefinedDate);"
              @mouseover="previewPredefinedDates(predefinedDate, true);"
              @mouseleave="previewPredefinedDates(predefinedDate, false);"
            >
              <button
                class="btn btn-text btn-text-clean btn-thin"
                type="button"
              >
                {{ predefinedDate.label }}
              </button>
            </li>
          </ul>
        </aside>
      </div>
    </template>
  </VMenu>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  compareAsc, eachMonthOfInterval, sub, startOfQuarter,
  endOfQuarter, subQuarters, set, getDaysInMonth, getMonth, getYear,
} from 'date-fns';
import { sv, enUS } from 'date-fns/locale';
import { equalLiteral } from 'Utils/general';
import { dateLabels, DATE_OPTIONS_LOOKUP, getKeyByDate, convertDatesToDateObject } from 'Utils/date';
import { format, adjustForUTCOffset } from 'Utils/dateHelpers';
import { wrapPropsIntoLabelValues } from 'Utils/tcselectHelpers';

const CURRENT_YEAR = new Date().getFullYear();

export default {
  props: {
    excludePredefined: {
      type: Array,
      default() {
        return [];
      },
    },
    dateObject: {
      type: Object,
      default() {
        return {};
      },
    },
    emptyAllowed: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    disableFutureDates: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    closeAfterSelect: {
      type: Boolean,
      default: true,
    },
    useBadgeStyling: {
      type: Boolean,
      default: false,
    },
    useFocusBriefly: {
      type: Boolean,
      default: false,
    },
    popperSettings: {
      type: Object,
      default: () => ({
        shift: true,
        'shift-cross-axis': true,
        strategy: 'fixed',
        container: false,
      }),
    },
  },
  emits: ['update:date-object', 'update:date-span', 'remove'],
  data() {
    return {
      selected: '12m',
      year: CURRENT_YEAR,
      date: {
        startDate: '',
        endDate: '',
      },
      previewingPredefined: false,
      currentDate: format(new Date(), 'yyyy-MM'),
      yearLimit: CURRENT_YEAR + 3,
      dateOption: [{ value: '12m', label: 'De senaste 12 månaderna' }], // gettext
      dateRangeColorized: [],
      dateRange: [],
      /* eslint-disable object-curly-newline */
      months: [
        { label: 'Jan', value: 0, quarter: 'Q1', disabled: false, date: `${CURRENT_YEAR}/0`, selected: false },
        { label: 'Feb', value: 1, quarter: 'Q1', disabled: false, date: `${CURRENT_YEAR}/1`, selected: false },
        { label: 'Mar', value: 2, quarter: 'Q1', disabled: false, date: `${CURRENT_YEAR}/2`, selected: false },
        { label: 'Apr', value: 3, quarter: 'Q2', disabled: false, date: `${CURRENT_YEAR}/3`, selected: false },
        { label: 'Maj', value: 4, quarter: 'Q2', disabled: false, date: `${CURRENT_YEAR}/4`, selected: false },
        { label: 'Jun', value: 5, quarter: 'Q2', disabled: false, date: `${CURRENT_YEAR}/5`, selected: false },
        { label: 'Jul', value: 6, quarter: 'Q3', disabled: false, date: `${CURRENT_YEAR}/6`, selected: false },
        { label: 'Aug', value: 7, quarter: 'Q3', disabled: false, date: `${CURRENT_YEAR}/7`, selected: false },
        { label: 'Sep', value: 8, quarter: 'Q3', disabled: false, date: `${CURRENT_YEAR}/8`, selected: false },
        { label: 'Okt', value: 9, quarter: 'Q4', disabled: false, date: `${CURRENT_YEAR}/9`, selected: false },
        { label: 'Nov', value: 10, quarter: 'Q4', disabled: false, date: `${CURRENT_YEAR}/10`, selected: false },
        { label: 'Dec', value: 11, quarter: 'Q4', disabled: false, date: `${CURRENT_YEAR}/11`, selected: false },
      ],
      /* eslint-enable object-curly-newline */
      isOpen: false,
      startOfTrustcruit: format(new Date(2015, 10), 'yyyy-MM'),
      yearTransitionClasses: {
        left: true,
        right: false,
      },
    };
  },
  computed: {
    ...mapState({
      modalCardOpenedFrom: (state) => state.cards.modalCardOpenedFrom,
    }),
    dateQueryIsAvailable() {
      return !!this.$route.query.dateGte || !!this.$route.query.dateLte;
    },
    selectedIsQuarter() {
      return this.months.some((m) => m.quarter === this.selected.split('-')[0]);
    },
    disabledQuarters() {
      return this.quarters
        .filter((q) => this.months
          .filter((month) => month.quarter === q)
          .some((month) => month.disabled));
    },
    predefinedDates() {
      // delete all feedback in boards
      const usedDateLabels = dateLabels();
      if (this.excludePredefined) this.excludePredefined.forEach((item) => delete usedDateLabels[item]);
      delete usedDateLabels.absolute;
      return wrapPropsIntoLabelValues(usedDateLabels);
    },
    readableDate() {
      if (!this.date.startDate && !this.date.endDate && this.emptyAllowed) return this.$gettext('Välj ett datumspann');
      if (this.selected === 'all_time') return dateLabels().all_time;
      if (this.dateObject.type === 'relative') return `${dateLabels()[this.dateOption[0]?.value]}`;
      if (this.date.startDate === this.date.endDate && this.date.startDate !== null) return this.date.startDate;
      if (this.date.startDate && this.date.endDate) return `${this.$gettext('Från')} ${this.date.startDate} ${this.$gettext('till')} ${this.date.endDate}`;
      if (this.date.startDate && !this.date.endDate) return `${this.$gettext('Från')} ${this.date.startDate} ${this.$gettext('till')}`;
      return dateLabels().all_time;
    },
    quarters() { return [...new Set(this.months.map((m) => m.quarter))]; },
  },
  watch: {
    isOpen(newVal) {
      if (!newVal && this.date.startDate && !this.date.endDate) {
        this.date.endDate = this.date.startDate;
        this.setDate([this.date.startDate, this.date.endDate]);
      }
      if (this.date.endDate) this.changeYear(getYear(new Date(this.date.endDate)) - this.year);
    },
    dateObject: {
      deep: true,
      handler(newVal, oldVal) { if (!equalLiteral(newVal, oldVal)) this.setup(newVal); },
    },
    date: {
      deep: true,
      handler(newVal, oldVal) { if (!equalLiteral(newVal, oldVal)) this.$emit('update:date-span', newVal); },
    },
    'this.$language.current': {
      immediate: true,
      handler(newVal, oldVal) { if (newVal !== oldVal) this.monthLabelLanguage(newVal); },
    },
    dateRangeColorized: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.months = this.getSelectedMonths(this.months);
        }
      },
    },
    year(newVal, oldVal) {
      if (newVal !== oldVal) this.months = this.getDisabledMonths(this.months);
      if (this.date.startDate && this.date.endDate) this.colorizeDateRange();
      else {
        this.months = this.getSelectedMonths(this.months);
      }
    },
  },
  mounted() {
    if (this.disableFutureDates) this.yearLimit = CURRENT_YEAR;
    this.$nextTick(() => {
      if (this.dateQueryIsAvailable) {
        const datesFromQuery = this.getDatesFromQuery();
        const dateObj = convertDatesToDateObject(datesFromQuery);
        this.setup(dateObj);
      } else if (Object.keys(this.dateObject).length !== 0 || this.emptyAllowed) {
        this.setup(this.dateObject);
      } else {
        this.selectPredefinedDates({ value: '12m' });
      }
    });
  },
  methods: {
    ...mapActions([
      'addCloseHandler',
      'removeCloseHandler',
      'removeAllCloseHandlers',
    ]),
    getDatesFromQuery() {
      return [
        format(new Date(this.$route.query.dateGte), 'yyyy-MM-dd') || null,
        (this.$route.query.dateLte && format(new Date(this.$route.query.dateLte), 'yyyy-MM-dd'))
          || this.currentDate,
      ];// ? Defaults to todays date, or "all time"
    },

    showQuarterClasses(q) {
      return this.selected === q;
    },
    monthClasses(month) {
      let selected = this.selected.split('-')?.[0];
      const [selectedYear, selectedMonth] = month?.date?.split('/') || [];
      const isInQuarter = this.quarters.includes(selected);
      const isStartAndEnd = this.date.startDate === this.date.endDate;
      const isInFirstColorized = selectedYear && selectedMonth
        ? format(new Date(selectedYear, selectedMonth), 'yyyy-MM') === this.dateRangeColorized[0]
        : false;
      const isInLastColorized = selectedYear && selectedMonth
        ? format(new Date(selectedYear, selectedMonth), 'yyyy-MM') === this.dateRangeColorized[this.dateRangeColorized.length - 1]
        : false;
      const shouldBeActivated = !isInQuarter || this.previewingPredefined;

      return {
        range: month.selected,
        active: (month.selected && isInFirstColorized && shouldBeActivated)
              || (month.selected && isInLastColorized && shouldBeActivated),
        'start-date': (isStartAndEnd || shouldBeActivated) && isInFirstColorized,
        'end-date': (isStartAndEnd || shouldBeActivated) && isInLastColorized,
        preview: this.previewingPredefined,
      };
    },
    clearRange() {
      this.dateRangeColorized = [];
    },
    colorizeDateRange(startAndEndDate = this.date) {
      if (!startAndEndDate.startDate && !startAndEndDate.endDate) this.dateRangeColorized = [];
      else this.dateRangeColorized = this.gatherMonthsBetween(startAndEndDate.startDate, startAndEndDate.endDate);
    },
    clearDates() {
      this.date.endDate = null;
      this.date.startDate = null;
    },
    toggleDatePicker(e) {
      if (this.isOpen) this.closeDatePicker();
      else this.openDatePicker(e);
    },
    openDatePicker() {
      if (this.disabled) return;

      this.isOpen = true;
      this.removeAllCloseHandlers();
      this.addCloseHandler(this.closeDatePicker);
    },
    closeDatePicker() {
      this.isOpen = false;
      this.removeCloseHandler();
    },
    changeYear(direction) {
      if (this.year <= 2015 && direction === -1 || this.yearLimit === this.year && direction === 1) return;
      this.yearTransitionClasses = {
        left: direction <= 0,
        right: direction > 0,
      };

      this.year += direction;
      this.months.forEach((m) => { m.date = this.year + m.date.substring(m.date.indexOf('/')); });
    },
    selectQuarter(quarter) {
      if (this.disabledQuarters.includes(quarter)) return;
      this.selected = `${quarter}-${this.year}`;
      this.clearDates();
      switch (quarter) {
        case 'Q1':
          this.date.startDate = this.formatDate(this.year, 0);
          this.date.endDate = this.formatDate(this.year, 2);
          break;
        case 'Q2':
          this.date.startDate = this.formatDate(this.year, 3);
          this.date.endDate = this.formatDate(this.year, 5);
          break;
        case 'Q3':
          this.date.startDate = this.formatDate(this.year, 6);
          this.date.endDate = this.formatDate(this.year, 8);
          break;
        case 'Q4':
          this.date.startDate = this.formatDate(this.year, 9);
          this.date.endDate = this.formatDate(this.year, 11);
          break;
        default:
          break;
      }
      this.clearRange();
      this.setDate([this.date.startDate, this.date.endDate], 'quarter');
      this.colorizeDateRange();
    },
    selectPredefinedDates(date) {
      this.selected = date.value;
      this.clearDates();
      this.previewingPredefined = false;

      this.date = this.convertPredefined(date);

      this.changeYear(getYear(new Date(this.date.endDate)) - this.year);
      this.$emit('update:date-object', DATE_OPTIONS_LOOKUP[date.value]);
      if (this.closeAfterSelect) this.closeDatePicker();

      this.clearRange();
      if (this.date.startDate !== null && this.date.endDate !== null) this.colorizeDateRange();
    },
    previewPredefinedDates(date, mouseOver = true) {
      const previewDate = this.convertPredefined(date);
      this.previewingPredefined = mouseOver;
      this.colorizeDateRange(mouseOver ? previewDate : this.date || {
        startDate: '',
        endDate: '',
      });
    },
    convertPredefined(date) {
      const [currentYear, currentMonth] = this.currentDate.split('-');
      let startDate;
      let endDate;
      switch (date.value) {
        case '3m':
          startDate = format(sub(new Date(), { months: 2 }), 'yyyy-MM');
          endDate = this.currentDate;
          break;
        case '6m':
          startDate = format(sub(new Date(), { months: 5 }), 'yyyy-MM');
          endDate = this.currentDate;
          break;
        case '12m':
          startDate = format(sub(new Date(), { months: 11 }), 'yyyy-MM');
          endDate = this.currentDate;
          break;
        case 'this_year':
          startDate = format(sub(new Date(), { months: currentMonth - 1 }), 'yyyy-MM');
          endDate = this.currentDate;
          break;
        case 'this_month':
          startDate = this.currentDate;
          endDate = this.currentDate;
          break;
        case 'last_month':
          startDate = format(sub(new Date(), { months: 1 }), 'yyyy-MM');
          endDate = format(sub(new Date(), { months: 1 }), 'yyyy-MM');
          break;
        case 'last_quarter':
          startDate = format(startOfQuarter(subQuarters(new Date(), 1)), 'yyyy-MM');
          endDate = format(endOfQuarter(subQuarters(new Date(), 1)), 'yyyy-MM');
          break;
        case 'last_year':
          startDate = format(sub(new Date(currentYear, 0), { years: 1 }), 'yyyy-MM');
          endDate = format(sub(new Date(currentYear, 11), { years: 1 }), 'yyyy-MM');
          break;
        case 'all_time':
          startDate = this.startOfTrustcruit;
          endDate = this.currentDate;
          break;
        default:
          break;
      }
      return { startDate, endDate };
    },
    formatDate(year, month) {
      return format(new Date(year, month), 'yyyy-MM');
    },
    selectMonth(newMonth) {
      if (newMonth.disabled) return;
      this.selected = '';

      let dateToAdd = this.formatDate(this.year, newMonth?.value);
      this.clearRange();
      if (!this.date.startDate || this.date.startDate && this.date.endDate) {
        this.date.endDate = null;
        this.date.startDate = dateToAdd;
        this.dateRangeColorized = [dateToAdd];
      } else if (this.endDateIsLater(this.date.startDate, dateToAdd) >= 0) {
        this.date.endDate = dateToAdd;
        this.setDate([this.date.startDate, this.date.endDate]);
        this.colorizeDateRange();
      } else {
        this.date.startDate = dateToAdd;
        this.dateRangeColorized = [dateToAdd];
      }
    },
    setDate(dates) { // ? Sets templated dates for datepicker
      if (dates != null && dates.length >= 2) {
        // ! Important to adjust for UTC offset, otherwise dates will be off by 1 day and then also 1 month when set
        const fromDate = format(set(adjustForUTCOffset(new Date(dates[0])), { date: 1 }), 'yyyy-MM-dd');
        const newToDate = adjustForUTCOffset(new Date(dates[1]));
        const toDate = format(set(newToDate, {
          date: getDaysInMonth(new Date(getYear(newToDate), getMonth(newToDate))),
        }), 'yyyy-MM-dd');

        this.dateRange = [fromDate, toDate];
      } else {
        this.dateRange = [];
      }
      const dateOptionType = dates.length > 0 ? 'absolute' : null;
      this.dateOption = wrapPropsIntoLabelValues(dateLabels()).filter((opt) => opt.value === dateOptionType);
      this.emitDateOption(this.dateOption, this.dateRange);
    },
    emitDateOption(dateOption = this.dateOption, [dateGte, dateLte] = this.dateRange) {
      if (dateOption?.length >= 1) {
        const dateObject = {
          ...DATE_OPTIONS_LOOKUP,
          absolute: {
            type: 'absolute',
            dateGte,
            dateLte,
            ...(this.selectedIsQuarter && { quarter: this.selected }),
            // ? Sync these keys to filterMerge "pick" in Utils/general
          },
        }?.[dateOption[0]?.value];
        if (dateObject) {
          this.$emit('update:date-object', dateObject);
          if (this.closeAfterSelect) this.closeDatePicker();
        }
      }
    },
    getDisabledMonths(months = this.months) {
      return months.map((m) => {
        const [selectedYear, selectedMonth] = m.date.split('/');
        const selectedFormatted = format(new Date(selectedYear, selectedMonth), 'yyyy-MM');
        if (selectedFormatted < this.startOfTrustcruit
        || (this.disableFutureDates && selectedFormatted > this.currentDate)) {
          m.disabled = true;
        } else {
          m.disabled = false;
        }
        return m;
      });
    },
    getSelectedMonths(months = this.months) {
      return months.map((m) => {
        const [selectedYear, selectedMonth] = m.date.split('/');
        m.selected = this.dateRangeColorized.includes(format(new Date(selectedYear, selectedMonth), 'yyyy-MM'));
        return m;
      });
    },
    endDateIsLater(start, end) {
      const [endYear, endMonth] = end.split('-');
      const [startYear, startMonth] = start.split('-');
      return compareAsc(new Date(endYear, endMonth), new Date(startYear, startMonth));
    },
    gatherMonthsBetween(start, end = null) {
      const [startYear, startMonth] = start.split('-');
      const [endYear, endMonth] = end ? end.split('-') : [startYear, startMonth];

      return eachMonthOfInterval({
        start: sub(new Date(startYear, startMonth), { months: 1 }),
        end: sub(new Date(endYear, endMonth), { months: 1 }),
      }).map((date) => format(date, 'yyyy-MM'));
    },
    monthLabelLanguage(lang = 'en-us') {
      if (lang === 'sv-se') lang = sv;
      if (lang === 'en-us') lang = enUS;
      return this.months.map((month, index) => {
        month.label = lang?.localize?.month(index, { width: 'abbreviated' });
        return month;
      });
    },
    setup(dateObject) {
      let dateOption = null;
      if (dateObject.type === 'absolute'
        && dateObject.dateGte !== undefined
        && dateObject.dateLte !== undefined) {
        this.selected = this.dateObject?.quarter || '';
        this.date.startDate = format(adjustForUTCOffset(new Date(dateObject.dateGte)), 'yyyy-MM');
        this.date.endDate = format(adjustForUTCOffset(new Date(dateObject.dateLte)), 'yyyy-MM');

        this.setDate([dateObject.dateGte, dateObject.dateLte]);
        this.colorizeDateRange();
        dateOption = 'absolute';
      } else if (dateObject.type === 'relative' || !Object.keys(dateObject).length && !this.emptyAllowed) {
        dateOption = getKeyByDate(dateObject) || '12m';
        this.selectPredefinedDates({ value: dateOption });
        this.selected = dateOption;
      } else if (!Object.keys(dateObject).length && this.emptyAllowed) {
        this.date.startDate = '';
        this.date.endDate = '';
        this.selected = '';
        this.dateRange = [];
        this.dateRangeColorized = [];
      }
      this.dateOption = wrapPropsIntoLabelValues(dateLabels()).filter((opt) => opt.value === dateOption);
      this.monthLabelLanguage(this.$language.current);
      this.months = this.getDisabledMonths(this.months);
    },
  },
};
</script>
