<template>
  <TcTable
    class="mt-2 tc-table-minus step-form-table"
    :aria-label="$pgettext('ARIA label — Stepform table', 'Tabell med formulär')"
    :is-responsive="true"
    :headers="headers"
    :rows="rows"
    :loading="loading"
  >
    <template #column-0="{ value }">
      <strong class="link link-inverted">{{ value?.name ?? value }}</strong>
    </template>
    <template #column-1="{ value }">
      <span
        class="badge no-user-select"
        :class="getBadgeColor(value)"
      >{{ getBadgeText(value) }}</span>
    </template>
    <template #column-2="{ value }">
      <span
        :class="getBenchmarkComparisonColor(value)"
      >{{ value ? value.split(' ')[0] : '–' }}</span>
      <span
        v-if="value?.split(' ')?.[1]"
        class="ml-0.5"
        v-text="value.split(' ')[1]"
      />
    </template>
    <template #column-4="{ value }">
      <span
        :class="getBenchmarkComparisonColor(value)"
      >{{ value ? value.split(' ')[0] : '–' }}</span>
      <span
        v-if="value?.split(' ')?.[1]"
        class="ml-0.5"
        v-text="value.split(' ')[1]"
      />
    </template>
    <template #column-6="{ value, row }">
      <div class="btn-group">
        <a
          v-if="getFirstRowActions(row)?.preview"
          v-tooltip="{
            content: btnTooltipsEnabled
              ? $gettext(
                'Förhandsvisa ”%{proxy}” formuläret i en ny flik',
                { proxy: getFirstRowName(row)?.externalName ?? $gettext('första') } )
              : null,
            placement: 'left'
          }"
          class="btn btn-secondary"
          tabindex="0"
          :href="`${BASE_URL}${getFirstRowActions(row).preview}`"
          target="_blank"
          rel="noopener noreferrer"
          @click="sendFeatureUseEvent(true)"
        >
          <i class="zmdi zmdi-open-in-new" />
          <span class="ml-0.5">
            {{ $gettext('Förhandsvisa') }}
          </span>
        </a>
        <template v-if="value.surveyState === 'ACTIVE' || value.surveyState === 'PAUSED'">
          <router-link
            v-tooltip="{ content: btnTooltipsEnabled ? translations.chartBtn : null, placement: 'left' }"
            :to="{ name:'form', params:{ level: value.step } }"
            :title="translations.chartBtn"
            class="btn btn-icon"
            :class="{ 'btn-primary': !btnTooltipsEnabled, 'btn-secondary': btnTooltipsEnabled }"
          >
            <i-material-symbols-bar-chart-rounded />
            <span class="ml-0.5 hidden-large-up">
              {{ $gettext('Se data') }}
            </span>
          </router-link>
        </template>
        <template v-else>
          <button
            class="btn badge-activatewall btn-thin"
            @click="activateStep(value.step, value.surveyState === 'INACTIVE')"
          >
            {{ value.surveyState === 'INACTIVE' ? $gettext('Aktivera') : $gettext('Återaktivera') }}
          </button>
        </template>
      </div>
    </template>
    <template #column-sub-0="{ value }">
      <div class="pl-2">
        <a
          v-if="value?.actions?.preview"
          class="link"
          tabindex="0"
          :href="`${BASE_URL}${value.actions.preview}` || ''"
          target="_blank"
          rel="noopener noreferrer"
          @click="sendFeatureUseEvent(true)"
        >
          {{ value?.name ?? '' }}
        </a>
      </div>
    </template>
    <template #column-sub-1="{ value }">
      <span
        class="badge no-user-select"
        :class="getBadgeColor(value, true)"
      >{{ getBadgeText(value, true) }}</span>
    </template>
    <template #column-sub-2="{ value }">
      <span
        :class="getBenchmarkComparisonColor(value)"
      >{{ value ? value.split(' ')[0] : '–' }}</span>
      <span
        v-if="value?.split(' ')?.[1]"
        class="ml-0.5"
        v-text="value.split(' ')[1]"
      />
    </template>
    <template #column-sub-4="{ value }">
      <span
        :class="getBenchmarkComparisonColor(value)"
      >{{ value ? value.split(' ')[0] : '–' }}</span>
      <span
        v-if="value?.split(' ')?.[1]"
        class="ml-0.5"
        v-text="value.split(' ')[1]"
      />
    </template>
    <template #column-sub-6="{ value }">
      <div class="btn-group">
        <a
          v-if="value.preview"
          v-tooltip="{ content: btnTooltipsEnabled ? translations.previewBtn : null, placement: 'left' }"
          class="btn btn-secondary"
          tabindex="0"
          :href="`${BASE_URL}${value?.preview}` || ''"
          target="_blank"
          rel="noopener noreferrer"
          @click="sendFeatureUseEvent(true)"
        >
          <i class="zmdi zmdi-open-in-new" />
          <span class="ml-0.5">
            {{ $gettext('Förhandsvisa') }}
          </span>
        </a>
        <router-link
          v-tooltip="{ content: btnTooltipsEnabled ? translations.chartBtn : null, placement: 'left' }"
          :to="{ name:'form', params:{ level: value.step }, query: { proxy: value.proxy } }"
          :title="translations.chartBtn"
          class="btn btn-secondary btn-icon"
        >
          <i-material-symbols-bar-chart-rounded />
          <span class="ml-0.5 hidden-large-up">
            {{ $gettext('Se data') }}
          </span>
        </router-link>
      </div>
    </template>
  </TcTable>
</template>

<script setup>
import { computed, ref, reactive } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import gaPush from '@/plugins/googleAnalytics';
import { roundNumber, translateTerm } from 'Utils/general';
import { BASE_URL } from 'Utils/urls';
import RequestPaginator from 'Utils/fresh-paginators/RequestPaginator';
import FilterPaginator from 'Utils/fresh-paginators/FilterPaginator';
import useSurveyList from 'Composables/useSurveyList';
import TcTable from 'Components/parts/table/TcTable';
import gettext from '@/gettext';
import { store } from '@/store';

const { $gettext, $pgettext } = gettext;

const activateStep = (level, activate) => {
  gaPush({
    event: '[TC] Clicked',
    eventAction: 'Clicked',
    eventCategory: 'Feature Use',
    eventLabel: activate
      ? 'Clicked "Activate" on row in StepFormTable'
      : 'Clicked "Reactivate" on row in StepFormTable',
    hitType: 'event',
  });
  const step = translateTerm(level);
  if (window?.Intercom) {
    window.Intercom('showNewMessage', activate
      ? $pgettext('Intercom - activate step chat', 'Hej! Jag vill aktivera formuläret %{step}. ', { step })
      : $pgettext('Intercom - reactivate step chat', 'Hej! Jag vill återaktivera formuläret %{step}. ', { step }));
  }
};

const props = defineProps({
  allSteps: {
    type: Array,
    default: () => [],
  },
  hideInactiveSteps: {
    type: Boolean,
    default: false,
  },
  gettingStats: {
    type: Boolean,
    default: false,
  },
  stats: {
    type: Object,
    default: null,
  },
  globalStats: {
    type: Object,
    default: null,
  },
  proxyStats: {
    type: Object,
    default: null,
  },
  tinyForms: {
    type: Object,
    default: () => ({}),
  },
  tinyPageFormsPageSize: {
    type: Number,
    default: 10,
  },
});

const breakpoints = useBreakpoints({ large: 992 });
const btnTooltipsEnabled = breakpoints.greaterOrEqual('large'); // Disable tooltips on small screens because of overflow & touch hindrances

const translations = computed(() => ({
  chartBtn: $gettext('Se data för detta formulär'),
  previewBtn: $gettext('Förhandsvisa formulär i ny flik'),
}));

const {
  hardCodedSurveysSorted,
  isLoadingForms,
} = useSurveyList();

const extractPercentages = (str) => str?.split(' ').map((v) => v.replace('%', '').replace(/[()]/g, '').trim());

const sortStatus = (a, b) => {
  const stateOrder = ['ACTIVE', 'PAUSED', 'INACTIVE'];
  return stateOrder.indexOf(a) - stateOrder.indexOf(b);
};

const headers = [
  {
    label: $pgettext('Table Header — Survey Name', 'Namn'),
    sortFn: true, // can be fn or bool
  },
  {
    label: $gettext('Status'),
    sortFn: (rows) => rows.sort((a, b) => sortStatus(a.data[1], b.data[1])),
  },
  {
    label: $gettext('Svarsfrekvens (Globalt)'),
    sortFn: (rows) => rows.sort((a, b) => {
      const value1 = parseFloat(extractPercentages(a.data[2])?.[0]) || 0;
      const value2 = parseFloat(extractPercentages(b.data[2])?.[0]) || 0;
      return value2 - value1;
    }),
    tooltipFn: (currentRowData) => {
      if (!currentRowData) return null;
      const [val1, val2] = extractPercentages(currentRowData);
      return $pgettext('Tooltip — StepFormTable, Svarsfrekvens (Globalt)', 'Svarsfrekvens %{val1}% (Globalt %{val2}%)', { val1, val2 });
    },
  },
  {
    label: $gettext('Antal svar'),
    sortFn: (rows) => rows.sort((a, b) => {
      const value1 = parseFloat(a.data[3]) || 0;
      const value2 = parseFloat(b.data[3]) || 0;
      return value2 - value1;
    }),
    tooltipFn: (currentRowData) => {
      if (!currentRowData) return null;
      return $pgettext('Tooltip — StepFormTable, Antal svar', 'Antalet svarade formulär: %{val} st', { val: currentRowData });
    },
  },
  {
    label: $gettext('Öppnade (Globalt)'),
    sortFn: (rows) => rows.sort((a, b) => {
      const value1 = parseFloat(extractPercentages(a.data[4])?.[0]) || 0;
      const value2 = parseFloat(extractPercentages(b.data[4])?.[0]) || 0;
      return value2 - value1;
    }),
    tooltipFn: (currentRowData) => {
      if (!currentRowData) return null;
      const [val1, val2] = extractPercentages(currentRowData);
      return $pgettext('Tooltip — StepFormTable, Öppnade (Globalt)', 'Öppnade %{val1}% (Globalt %{val2}%)', { val1, val2 });
    },
  },
  {
    label: $gettext('Skickade'),
    sortFn: (rows) => rows.sort((a, b) => {
      const value1 = parseFloat(a.data[5]) || 0;
      const value2 = parseFloat(b.data[5]) || 0;
      return value2 - value1;
    }),
    tooltipFn: (currentRowData) => {
      if (!currentRowData) return null;
      return $pgettext('Tooltip — StepFormTable, Skickade', 'Antalet skickade formulär: %{val} st', { val: currentRowData });
    },
  },
  {
    label: '', // Actions
    sortFn: false,
  },
];

const getBadgeColor = (status, isProxy) => {
  if (status === 'INACTIVE' && !isProxy) return '';
  if (status === 'ACTIVE') return 'badge-success';
  return 'badge-warning';
};
const getBadgeText = (status, isProxy) => {
  if (status === 'INACTIVE' && !isProxy) return $gettext('Inaktiv');
  if (status === 'ACTIVE') return $gettext('Aktiv');
  return $gettext('Pausad');
};

const getBenchmarkComparisonColor = (value) => {
  if (!value) return '';
  let [value1, value2] = extractPercentages(value);
  value1 = parseFloat(value1);
  value2 = parseFloat(value2);
  if (Number.isNaN(value1) || Number.isNaN(value2)) return '';
  if (value1 >= value2) return 'tc-color-green';
  return 'tc-color-red';
};

const getSurveyState = (step, isActive) => {
  const allSurveysInactive = props.tinyForms[step]?.results?.every((survey) => survey.active === false);
  if (allSurveysInactive && !isActive) return 'PAUSED';
  if (isActive) return 'ACTIVE';
  return 'INACTIVE';
};

const availableSurveys = computed(() => hardCodedSurveysSorted.value
  .filter((survey) => survey?.active === false && !props.allSteps?.includes(survey.step)));

const allSurveys = computed(() => {
  if (isLoadingForms.value) return [];

  const surveys = props.allSteps.map((step) => {
    const allSurveysInactive = props.tinyForms[step]?.results?.every((survey) => survey.active === false);
    return ({
      step,
      active: !allSurveysInactive,
      name: translateTerm(step),
    });
  });

  return [...surveys, ...availableSurveys.value].sort((a, b) => {
    const stateA = getSurveyState(a.step, a.active);
    const stateB = getSurveyState(b.step, b.active);
    return sortStatus(stateA, stateB);
  });
});

const loading = computed(() => props.gettingStats || isLoadingForms.value || store.state.customer.fetchingCustomer);

const getProxyStats = (proxyId, level) => {
  const proxyStats = props.proxyStats?.[proxyId] || {};
  if (!proxyStats || !proxyStats?.[level]) return {};
  return proxyStats?.[level];
};

const formatStatsData = (data, step) => {
  if (data?.respond_perc == null) return { respondPerc: null, globalRespondPerc: '', respondedCount: null, openPerc: null, globalOpenPerc: '', applicantCount: null };
  const globalStats = props.globalStats?.[step] || {};

  const respondPerc = `${roundNumber((data?.respond_perc ?? 0) * 100)}%`;
  const globalRespondPerc = globalStats?.respond_perc ? `(${roundNumber(globalStats.respond_perc * 100)}%)` : '';
  const respondedCount = data?.responded_count || '0';
  const openPerc = `${roundNumber((data?.open_perc ?? 0) * 100)}%`;
  const globalOpenPerc = globalStats?.open_perc ? `(${roundNumber(globalStats.open_perc * 100)}%)` : '';
  const applicantCount = data?.applicant_count || '0';
  return { respondPerc, globalRespondPerc, respondedCount, openPerc, globalOpenPerc, applicantCount };
};

const appendInternalName = (proxyObj) => {
  if (proxyObj?.internal_name && proxyObj.name !== proxyObj.internal_name) return ` (${proxyObj.internal_name})`;
  return '';
};

const formatSurveyData = (survey, isProxy) => {
  // eslint-disable-next-line max-len, one-var, one-var-declaration-per-line
  let name, externalName, surveyState, respondPerc, globalRespondPerc, respondedCount, openPerc, globalOpenPerc, applicantCount, actions;

  if (isProxy) {
    const proxy = getProxyStats(survey.customer_proxy_id, survey.level);
    name = `${survey.customer_proxy_name}${appendInternalName({ name: survey.customer_proxy_name, internal_name: survey.customer_proxy_internal_name })}`;
    externalName = survey.customer_proxy_name;
    surveyState = getSurveyState(survey.step, survey.active);
    ({ respondPerc, globalRespondPerc, respondedCount, openPerc, globalOpenPerc, applicantCount } = formatStatsData(proxy, survey.level)); // eslint-disable-line max-len
    actions = { step: survey.level, proxy: survey.customer_proxy_id, preview: survey.test_url, surveyState };
  } else {
    name = getSurveyState(survey.step, survey.active) === 'INACTIVE' ? survey.name : translateTerm(survey.step);
    externalName = name;
    surveyState = getSurveyState(survey.step, survey.active);
    ({ respondPerc, globalRespondPerc, respondedCount, openPerc, globalOpenPerc, applicantCount } = formatStatsData(props.stats?.[survey.step], survey.step)); // eslint-disable-line max-len
    actions = { step: survey.step, surveyState };
  }
  return [
    { name, actions, externalName }, // AKA. NAME_COL_INDEX
    surveyState,
    respondPerc || globalRespondPerc ? `${respondPerc || '–'} ${globalRespondPerc}` : null,
    respondedCount || '–',
    openPerc || globalOpenPerc ? `${openPerc || '–'} ${globalOpenPerc}` : null,
    applicantCount || '–',
    actions, // AKA. ACTIONS_COL_INDEX
  ];
};
const NAME_COL_INDEX = 0;
const getFirstRowName = (row) => row.children.paginator?.stack?.[0]?.[NAME_COL_INDEX];
const ACTIONS_COL_INDEX = 6;
const getFirstRowActions = (row) => row.children.paginator?.stack?.[0]?.[ACTIONS_COL_INDEX];
const searchTerm = ref('');
const searchFn = (opt) => {
  if (searchTerm.value === '') return true;
  const optLowerCase = opt?.label?.toLowerCase() ?? opt?.value?.toLowerCase() ?? '';
  return optLowerCase.indexOf(searchTerm.value.toLowerCase()) > -1;
};

const wrapResultsFn = (response) => {
  if (response?.results?.length > 0) {
    return {
      ...response,
      results: response.results.map((opt) => formatSurveyData(opt, true)),
    };
  }
  return { next: null, prev: null, results: [], ...response };
};

const tinyFormsPaginators = computed(() => {
  if (isLoadingForms.value) return {};
  const paginators = new Map();
  const steps = Object.keys(props.tinyForms);
  steps.forEach((step) => {
    const response = props.tinyForms[step];
    const requestPaginator = new RequestPaginator(response, wrapResultsFn);
    const filterPaginator = reactive(new FilterPaginator(requestPaginator, searchFn));
    paginators.set(step, filterPaginator);
  });
  return paginators;
});

const rows = computed(() => {
  if ((props.gettingStats === null || props.gettingStats) || props.stats === null) return [];

  return allSurveys.value.map((survey, index) => ({
    slug: `${survey.step}-${index}`,
    data: formatSurveyData(survey),
    children: {
      paginator: tinyFormsPaginators.value.get(survey.step),
      pageSize: props.tinyPageFormsPageSize,
    },
  }))
    .filter((survey) => {
      if (!props.hideInactiveSteps) return true;
      return survey.data[1] === 'ACTIVE' || survey.children.paginator?.stack.some((child) => child[1] === 'ACTIVE');
    });
});

const sendFeatureUseEvent = (isSubrow) => {
  gaPush({
    event: '[TC] Clicked',
    eventAction: 'Clicked',
    eventCategory: 'Feature Use',
    eventLabel: isSubrow
      ? 'Clicked "Preview" on sub row in StepFormTable'
      : 'Clicked "Preview" on row in StepFormTable',
    hitType: 'event',
  });
};

</script>
