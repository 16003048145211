import { getBaseUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';
import { vm } from '@/vm';
import { store } from '@/store';

// Whitelist of question IDs that are allowed to be used in the topic filter
export const TOPIC_FILTER_WHITELIST = [
  396,
  495,
  503,
  504,
  650,
  863,
  1029,
  1030,
  1102,
  1123,
  1124,
  1152,
  1163,
  1183,
  1191,
  1229,
  1270,
  1359,
  1360,
  1363,
  1381,
  1398,
  1405,
  1407,
  1423,
  1433,
  1527,
  1560,
  1578,
  1592,
  1601,
  1727,
  1779,
  1842,
  1862,
  1940,
  2141,
  2142,
  2214,
  2382,
  2462,
  2594,
  2656,
  3530,
  5463,
  5578,
  5579,
  5687,
  5688,
];

export function isTopicable(qId) {
  return TOPIC_FILTER_WHITELIST.includes(qId);
}

/**
 ** topicsAPI.list() - Lists all available topics in a paginated list with filter support.
 * @returns {Promise} (un-)paginated response from `/shinyform/api/topics/`
*/
function list() {
  const lang = vm()?.$language?.current || store.state.languageSettings?.language || 'en-us';
  const req = getBaseUrl('/shinyform/api/topics/').set('Accept-Language', lang);
  return req.then((response) => response.body.results, handleAuthError);
}

export default { list };
